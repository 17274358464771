<template>
    <v-snackbar
        v-model="hideShowSnackbar"
        :bottom="getPosX === 'bottom'"
        :top="getPosX === 'top'"
        :left="getPosX === 'left'"
        :right="getPosX === 'right'"
        :vertical="getMode === 'vertical'"
        :multi-line="getMode === 'multi-line'"
        :timeout="getTimeout"
        :color="snackbarColor"
        transition="slide-y-reverse-transition"
    >
        {{ getSnackbarMessage }}
        <template v-slot:action="{ attrs }">
            <v-btn icon text v-bind="attrs" @click="hide">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as snackbarActions from "../../store/actions/snackbar";

export default {
    data: function() {
        return {
            snackbarColors: {
                'SUCCESS': 'success',
                'WARNING': 'orange lighten-2',
                'ERROR': 'error'
            }
        }
    },
    computed: {
        ...mapGetters({
            hideShowSnackbar: snackbarActions.SNACKBAR_GET_STATUS,
            getSnackbarMessage: snackbarActions.SNACKBAR_GET_MESSAGE,
            //getActionButton: snackbarActions.SNACKBAR_GET_ACTION_BUTTON,
            getSnackbarMessageType: snackbarActions.SNACKBAR_GET_MESSAGE_TYPE,
            getMode: snackbarActions.SNACKBAR_GET_MODE,
            getPosX: snackbarActions.SNACKBAR_GET_X,
            getPosY: snackbarActions.SNACKBAR_GET_Y,
            getTimeout: snackbarActions.SNACKBAR_GET_TIMEOUT,
            //getSnackBarState: 
        }),
        snackbarColor: function() {
            return this.snackbarColors[this.getSnackbarMessageType] || 'grey darken-4'
        }
    },
    methods: {
        ...mapActions({
            hideSnackbar: snackbarActions.SNACKBAR_HIDE
        }),
        hide() {
            this.hideSnackbar();
        }
    }
};
</script>

<style></style>